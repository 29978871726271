import React from 'react';
import SEO from '../../../seo';
import Layout from '../../../shared/Layout';
import useReduxHandler from '../../../../hooks/useReduxHandler';

const NotFoundPage = (props) => {
	console.log('porps', props);
	// const { pageContext, path } = props;
	const { pageContext, path, location } = props;

	const {
		slug,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		searchOptions,
	} = pageContext;

	// const { slug, searchOptions } = pageContext;
	const { isLoggedIn } =
		useReduxHandler();
	const isLogged = isLoggedIn();
	const color = isLogged && 'dark';

	return (
		<>
			<Layout
				isPrivate
				isLogged={isLogged}
				type="private"
				viewMainSection={false}
				viewSideBar={false}
				classNameMain="bg-white main-partner-portal"
				header={color}
				path={path}
				fluid={slug === 'api'}
				slug={slug}
				searchOptions={searchOptions}
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
			>
				<SEO title="404: Not found" />
				<div
					id="continer404"
					style={{
						width: '100%',
						height: '71vh',
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<h1>NOT FOUND</h1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
				</div>
			</Layout>
		</>
	);
};

export default NotFoundPage;
